.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}


strong {
    font-family: "Rubik-bold";
    font-weight: bold;
}

b {
    font-family: "Rubik-medium";
    font-weight: 500;
}

.no-action {
    touch-action: none;
    pointer-events: none;
}