// @import "../00-settings/index.scss";
// @import "../01-tools/index.scss";
// @import "../02-generic/index.scss";

$msv-order-template-add-line-search-button-width-height:10px;

.msc-promo-code {
    &-heading {
        @include font-heading-h5-l();
        padding: 30px 0 25px 35px;
        border-bottom: 0.5px solid $msv-gray-50;

        @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l) {
            @include font-heading-h5-m();
        }

        @media (max-width: $msv-breakpoint-m) {
            @include font-heading-h5-s();
        }
    }

    &__group {
        padding: 24px 35px;
        display: flex;
    }

    &__apply-btn {
        @include primary-button-light();
        @include add-icon($msv-Search);
        margin-left: 9px;
        width: 52px;
        height: 52px;
        border-radius: 3px;
        min-width: unset;
        padding: 0;
        cursor: pointer;

        &::before {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }

        &:focus::before {
            content: $msv-Search;
            position: static;
            border-radius: unset;
            padding: 12px;
        }
    }

    &__input-box {
        @include form-input-box();
        text-transform: none;
        color: $msv-font-primary-color;
        padding: 14px 0 14px 9px;
        border: 0.5px solid $msv-gray-50;
        border-radius: 3px;
        width: calc(100% - 62px);
    }

    &__discount, &__line-container {
        @include font-body-regular-s();
        color: $msv-font-primary-color;
        display: flex;
        justify-content: flex-end;
        margin-right: 35px;
        margin-bottom: 10px;
    }

    &__line {
        &-value {
            display: flex;

            &-code {
                font-weight: 700;
                margin: 0 5px;
            }

            @include add-icon($msv-Checkbox-Circle-Checked);

            &::before {
                margin-right: 7px;
                font-size: 22px;
            }
        }

        &-discount-value {
            font-weight: $msv-font-weight-normal;
        }

        &__btn-remove {
            @include add-icon($msv-Cancel);
            background: transparent;
            border: none;
            cursor: pointer;
            color: $msv-text-color;
            margin-left: 10px;
        }
    }

    &__discount-heading {
        display: none;
    }
}
