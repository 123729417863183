$sgi-font-path: "../../../webfonts/sgi";
@font-face {
    font-family: "Avenir";
    src: local(""), url("#{$sgi-font-path}/Avenir-Book.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Avenir";
    src: url("#{$sgi-font-path}/Avenir-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Avenir";
    src: url("#{$sgi-font-path}/Avenir-Medium.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Avenir";
    src: url("#{$sgi-font-path}/Avenir-Heavy.woff") format("woff");
    font-weight: 600;
}

@font-face {
    font-family: "Avenir";
    src: url("#{$sgi-font-path}/Avenir-Black.woff") format("woff");
    font-weight: 900;
}

@font-face {
    font-family: "Avenir Next";
    src: url("#{$sgi-font-path}/AvenirNext-Regular.woff") format("woff");
    font-weight: 400;
}

//=============================================================================//
// FONT FAMILY
//===========================================================================//
$font-family-avenir: "Avenir", "Nunito Sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
$font-family-avenir-next: "Avenir Next", "Nunito Sans", "Source Sans Pro", Helvetica, Arial, sans-serif;
$font-family-nothing-you-can-do: "Nothing You Could Do";

//=============================================================================//
// FONT SIZES
//===========================================================================//

$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-24: 1.5rem;
$font-size-28: 1.75rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-42: 2.625rem;
$font-size-48: 3rem;


@mixin av-font($font-type: book, $font-size: 1rem, $line-height: 1) {
    font-family: $font-family-avenir;
    font-size: $font-size;
    line-height: $line-height;
    @if ($font-type == light) {
        font-weight: 300;
    } @else if ($font-type == book) {
        font-weight: 400;
    } @else if ($font-type == medium) {
        font-weight: 500;
    } @else if ($font-type == heavy) {
        font-weight: 600;
    } @else if ($font-type == black) {
        font-weight: 900;
    }
}

@mixin av-next-font($font-size: 1rem, $line-height: 1) {
    font-family: $font-family-avenir-next;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: 400;
}
