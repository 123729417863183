//=============================================================================//
// CART LINE ITEMS
//===========================================================================//

.ms-cart {
    .msc-cart-line .msc-cart-line__product-image {
        justify-content: center;
    }
    .msc-cart-line .msc-cart-line__product-image .msc_image {
        transform: none;
        max-width: 150px;
        height: auto;
    }
    .msc-cart-lines-group-wraper .msc-cartline-wraper {
        border: none;
        padding: 0;
    }
}

.ms-cart .msc-cart__empty-cart {
    margin: 40px 0 30px !important;
    padding: 0 30px;
    border: none;
}

.ms-cart-icon__minicartmodal-container .ms-cart-icon__cart-lines .msc-cart-lines-item .msc-cart-line__product-image .msc_image {
    height: auto;
}
