.sgi-new-arrival {
    .ms-product-collection {
        text-align: left;
        &__heading {
            @include font-heading-h1-l();
            margin-bottom: 20px;
            margin-top: 40px;
            padding-bottom: 30px;
            display: flex;
            align-items: baseline;
            color: var(--msv-search-result-heading-font-color);
            border-bottom: 1px solid #d1d1d1;
            padding-left: $msv-layout-container-padding-m;
            padding-right: $msv-layout-container-padding-m;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding-left: $msv-layout-container-padding-s;
                padding-right: $msv-layout-container-padding-s;
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                padding-left: $msv-layout-container-padding-l;
                padding-right: $msv-layout-container-padding-l;
            }
        }
        &__items {
            margin-top: 30px;
        }
        &__item {
            .msc-product__image {
                width: $msv-width-full;
                height: 89vw;
                @media (min-width: $msv-breakpoint-m) {
                    height: 42.25vw;
                }
                @media (min-width: $msv-breakpoint-l) {
                    height: 19vw;
                }
            }
        }
    }
}
