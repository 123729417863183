//=============================================================================//
// COLORS
//===========================================================================//

$color-primary-white: #fff;
$color-primary-black: #211b1b;
$color-primary-grey: #8d9092;
$color-primary-green: #3c592f;


$color-primary-light: #f3f5f3;

$color-secondary-grey: #4a4141;
$color-secondary-green: #38582f;

$color-secondary-blue: #0061af;
$color-secondary-yellow: #f8ff00;
$color-secondary-red: #e11b22;
$color-secondary-orange: #fbb148;
$color-secondary-teal: #01939d;
$color-secondary-brown: #585337;
$color-secondary-purple: #594e9f;