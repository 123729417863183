//=============================================================================//
// SGI STORE SELECTOR
//===========================================================================//

.ms-store-select {
    .ms-store-select__modal-body .ms-store-select__body_wrapper {
        display: flex;
        width: 100%;
    }

    .ms-store-select__search {
        margin-bottom: 0;
        .ms-store-select__search-see-all-stores {
            position: static;
            margin-top: 15px;
            &:focus-visible {
                outline: 1px dotted $color-primary-black !important;
            }
        }
    }
    .ms-store-select__search-form {
        .ms-store-select__search-input {
            height: 50px;
            max-height: 50px;
            width: 100%;
            &:not(:disabled):focus {
                box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
                filter: none;
            }
        }
        .ms-store-select__search-button {
            &.search-btn-disabled {
                cursor: default;
                user-select: none;
                pointer-events: none;
                touch-action: none;
                &:hover,
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .ms-store-select__location-line-item .ms-store-select__location-line-item-content {
        .ms-store-select__location-line-item-contact-info {
            .ms-store-select__location-line-shop-address-text {
                white-space: normal;
                @include media-breakpoint(md) {
                    white-space: pre-wrap;
                }
            }
        }
        .ms-store-select__location-line-shop-phone-section {
            padding-top: 0;
        }
        .ms-store-select__location-line-item-store-phone-number {
            margin-top: 13px;
            padding-left: 56px;
            @include media-breakpoint(md) {
                margin-right: 45px;
                padding-left: 0;
                margin-top: 0;
            }
        }
        .ms-store-select__location-line-shop-phone-text {
            @include font-body-regular-s();
            margin-bottom: $msv-store-selector-store-hours-details-margin-bottom;
        }
    }
}

.ms-store-select__locations {
    margin-top: 0;
    padding-top: 30px;
}

// No Store Found

.ms-store-select__no-locations {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// Store Locate Page UI

.ms-store-locate {
    .ms-store-select__no-locations {
        margin-top: 30px;
    }
    .ms-store-select__location {
        border: 0;
        padding-bottom: 0;
    }
    .ms-store-select__locations {
        max-height: 493px;
        padding: 30px 15px;
    }
    .ms-store-select__search {
        padding-bottom: 15px;
        margin-bottom: 0;
    }
    @include media-breakpoint(md) {
        .ms-store-select__search-see-all-stores,
        .ms-store-select__found-locations__text,
        .ms-store-select__search-form {
            padding-left: 5px;
        }
    }
    .ms-store-select__search-see-all-stores {
        margin-top: 15px;
        position: static;
    }
    .ms-store-select__search-form {
        .ms-store-select__search-input {
            width: 100%;
            &:not(:disabled):focus {
                box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
                filter: none;
            }
        }
    }
    .ms-store-select__location-line-item-set-as-preferred-store {
        border: 0;
        padding: 15px;
        @include media-breakpoint(md) {
            padding: 15px 0 15px 50px;
            text-align: left;
            justify-content: flex-end;
        }
    }
}
