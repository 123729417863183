//=============================================================================//
// CHECKOUT ITEMS
//===========================================================================//

// Image settings

.ms-checkout__line-items-delivery-group .msc-cart-line__product-image {
    width: 108px;
    justify-content: center;
}

.ms-checkout__line-item .msc-cart-line__product-image .msc_image {
    width: auto;
}

// Item Header
.ms-checkout__line-items-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ms-checkout__side {
    .ms-checkout__line-items {
        background-color: var(--msv-checkout-side-multiple-pickup-bg);
        border: 0.5px solid #c4c4c4;
        border-radius: 10px;
        padding: 0;
        margin-top: 20px;
        .ms-checkout__line-items-header {
            margin: 15px 10px;
            display: flex;
            justify-content: space-between;
            @include media-breakpoint(sm) {
                margin: 25px 55px;
            }
        }
        .ms-checkout__line-items-delivery-group {
            border-top: 1px solid var(--msv-checkout-shoppingbag-border);
            position: relative;
            padding: 15px;
            @include media-breakpoint(sm) {
                padding: 30px;
            }
            .msc-cart-line__product-title {
                word-break: break-word;
            }
        }
        .ms-checkout__line-items-group-title {
            @include add-icon($msv-DeliveryTruck, before);
            font-family: "Rubik-Medium";
            font-weight: 500;
            font-style: normal;
            font-size: var(--msv-h5-font-size-l);
            line-height: var(--msv-h5-line-height-l);
            color: var(--msv-checkout-line-text-font-color);
            &::before {
                position: relative;
                top: 4px;
                margin-right: 15px;
                font-size: 24px;
            }
        }
    }
}
