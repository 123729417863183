//=============================================================================//
// STUDY BASICS
//===========================================================================//
$sb-block-image-size: 150px;
.sgi-content-block__study-basics {
    > .row {
        background-color: $color-primary-light;
        padding-top: 50px;
        padding-bottom: 50px;
        @include media-breakpoint(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .row {
        flex-direction: column;
        @include media-breakpoint(lg) {
            flex-direction: row;
        }
        .study-basics-image {
            &.ms-content-block {
                margin-bottom: 0;
                .ms-content-block__image {
                    height: $sb-block-image-size;
                    width: $sb-block-image-size;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include media-breakpoint(lg) {
                        height: auto;
                        width: auto;
                        margin: 0;
                        display: block;
                    }
                    img {
                        height: $sb-block-image-size;
                        width: $sb-block-image-size;
                        object-fit: cover;
                        border-radius: 100px;
                        @include media-breakpoint(lg) {
                            width: 100%;
                            height: auto;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
        .study-basics-detail {
            &.ms-content-block {
                .ms-content-block__details {
                    align-items: center;
                    padding: 15px;
                    @include media-breakpoint(md) {
                        padding: 30px 30px 0;
                    }
                    @include media-breakpoint(lg) {
                        padding: 60px 60px 0;
                    }
                    .ms-content-block__text {
                        text-align: center;
                        @include media-breakpoint(lg) {
                        }
                    }
                }
            }
        }
    }
    .ms-content-block {
        .ms-content-block__details {
            .ms-content-block__cta {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                @include media-breakpoint(sm) {
                    flex-direction: row;
                }
                .msc-cta__primary,
                .msc-cta__secondary {
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    @include media-breakpoint(sm) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .ms-product-collection {
        margin-top: 30px;
        .msc-product__dimensions {
            display: none;
        }
    }
}

//=============================================================================//
// ALTAR ESSENTIALS
//===========================================================================//

.sgi-content-block__altar-essentials {
    .col-lg-8 {
        order: 2;
        @include media-breakpoint(lg) {
            padding-right: 30px;
            order: 0;
        }
        @include media-breakpoint(xl) {
            padding-right: 0;
        }
    }
    .col-lg-4 {
        width: 100%;
        margin-bottom: 40px;
        @include media-breakpoint(lg) {
            width: auto;
            margin-bottom: 0;
        }
    }
    .altar-essentials-details {
        @include media-breakpoint(xl) {
            margin-left: 75px;
        }
        &.ms-content-block {
            display: flex;
            align-items: center;
            background-color: $color-primary-green;
            border-radius: 15px;
            @include media-breakpoint(lg) {
                height: 100%;
            }
            .ms-content-block__image {
                display: none;
            }
            .ms-content-block__details {
                padding: 40px;
                @include media-breakpoint(lg) {
                    padding: 0 40px;
                }
                .ms-content-block__title,
                .ms-content-block__text {
                    color: $color-primary-white;
                }
                .ms-content-block__title {
                    @include av-font(heavy, $font-size-42, 1.16);
                }
                .ms-content-block__text {
                    @include av-font(book, $font-size-16, 1.3);
                }
            }
        }
    }
    .ms-product-collection[data-p-layout="vertical"] {
        .msc-product__image {
            height: 190px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .ms-product-collection[data-p-layout="horizontal"] {
        margin: auto;
        .msc-product {
            display: flex;
            align-items: center;
        }
        .msc-product__image {
            width: 190px;
            // height: auto;
            margin-right: 15px;
        }
        .msc-product__details {
            text-align: left;
        }
    }
}

//=============================================================================//
// EXPLORE TEXT CONTENT BLOCK
//===========================================================================//

.sgi-content-block__explore-text {
    margin-left: 30px;
    margin-right: 30px;
    .ms-content-block__details {
        &__additional-content__text {
            font-family: $font-family-avenir;
            color: $color-primary-black;
            font-weight: 900;
            font-size: 10vw;
            line-height: 1;
            @include media-breakpoint(xl) {
                font-size: 7rem;
            }
        }
        &__additional-content__paragraph {
            font-family: $font-family-nothing-you-can-do;
            color: $color-secondary-blue;
            font-size: 10vw;
            line-height: 0;
            padding-bottom: 70px;
            text-align: right;
            @include media-breakpoint(lg) {
                padding-right: 72px;
            }
            @include media-breakpoint(xl) {
                font-size: 7rem;
            }
        }
    }
}

//=============================================================================//
// NEW MEMBERS BLOCK
//===========================================================================//

// Recommended Intro Titles

.sgi-recommended-intro-titles {
    > .row > .col-12:first-child {
        order: 2;
        @include media-breakpoint(lg) {
            order: 0;
        }
    }
    .ms-content-block[data-m-layout="full-width-with-container"] .ms-content-block__details.withoutImage {
        padding: 0;
        @include media-breakpoint(lg) {
            padding: 120px 30px 60px;
        }
    }
}

// Recommended Intro Titles Block
// Wisdom Block
// Never Give Up Block
// New Human Revolution container
// Assemble altar space

.sgi-recommended-intro-titles,
.sgi-wisdom-block,
.sgi-never-give-up,
.sgi-assemble-altar-space,
.sgi-new-human-revolution-books {
    .ms-product-collection {
        .ms-product-collection__heading {
            margin-bottom: 20px;
            font-size: $font-size-32;
        }
        .msc-ss-carousel {
            @include media-breakpoint(lg) {
                padding-top: 0;
                width: 100% !important;
            }
        }
        .msc-product {
            .msc-product__title {
                margin-bottom: 10px;
                font-size: $font-size-18;
                white-space: normal;
            }
        }
        .ms-product-collection__item .msc-product__image .msc-empty_image {
            @include image-empty-placeholder(150px, 150px);
            height: 150px;
            width: 150px;
        }

        @include media-breakpoint(lg) {
            .msc-ss-carousel__flipper {
                right: 60%;
            }
            .msc-ss-carousel__flipper--next {
                right: 38%;
            }
        }
    }
}

// Wisdom Block and never give up block hide image on mobile

.sgi-wisdom-block,
.sgi-never-give-up {
    padding: 30px 0;
    border-top: 10px solid #f3f3f3;
    border-bottom: 10px solid #f3f3f3;
    @include media-breakpoint(md) {
        padding: 60px 0 30px;
    }
    .col-md-3 {
        display: none;
        @include media-breakpoint(md) {
            display: block;
        }
    }
}

// New Human Revolution container specific

.sgi-new-human-revolution-books {
    .container-heading {
        width: 100%;
        padding: 30px 30px 0 0;
        line-height: 1.3;
        text-align: center;
        @include media-breakpoint(lg) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            text-align: left;
        }
    }
    .ms-content-block__details.withoutImage {
        padding: 30px;
        @include media-breakpoint(lg) {
            padding: 80px 30px;
        }
    }
    > .row > .col-12:first-of-type {
        order: 2;
        @include media-breakpoint(lg) {
            order: 0;
        }
    }
}

//Assemble altar space

.sgi-assemble-altar-space {
    .container-heading {
        width: 100%;
        padding: 30px 30px 30px 0;
        line-height: 1.3;
        text-align: center;
        @include media-breakpoint(lg) {
            margin-bottom: 15px;
            padding-bottom: 15px;
            text-align: left;
        }
    }
    .ms-content-block.textplacement__center {
        padding: 0 30px;
        @include media-breakpoint(md) {
            padding: 60px 30px 0;
        }
        .ms-content-block__title {
            margin-bottom: 10px;
        }
        .ms-content-block__title,
        .ms-content-block__text {
            text-align: center;
        }
    }
    .ms-image-list {
        margin: 30px 0;
        .ms-image-list__list {
            margin: 0;
            display: flex;
            flex-direction: row;
        }
        .ms-image-list__list-item a {
            align-items: center;
            @include media-breakpoint(sm) {
                min-width: 120px;
                width: 120px;
                max-width: 120px;
            }
        }
    }
}

.container-heading-aligned .container-heading {
    width: 100%;
    padding: 30px 30px 30px 0;
    line-height: 1.3;
    text-align: center;
    @include media-breakpoint(lg){
        margin-bottom: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
}