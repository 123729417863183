//=============================================================================//
// SGI NAVIGATION MENU
//===========================================================================//
.ms-nav__list__item .ms-nav__list .ms-nav__list__item__button {
    @include media-breakpoint(md, max-width) {
        text-align: left;
    }
}
.ms-header .ms-nav {
    .ms-nav__list__item .havesubmenu .drawer .drawer__button {
        display: flex;
        padding-left: 0;
        .ms-nav__drawer-open,
        .ms-nav__drawer-close {
            order: 2;
            padding-right: 0;
            padding-left: 5px;
        }
    }
}

.ms-header .ms-nav > .ms-nav__list .ms-nav-close-button {
    @include media-breakpoint(md) {
        width: auto;
        margin-left: auto;
    }
}

.ms-header .ms-nav > .ms-nav__list > .ms-nav__list__item {
    > .ms-nav__list__item__link,
    > .ms-nav__list__item__button {
        @include media-breakpoint(md) {
            transition: transform ease-in-out 0.2s;
            &:hover,
            &:focus {
                &:not([aria-expanded="true"]) {
                    @include font-heading-h4-m();
                    transform: scale(1.045);
                }
            }
            &:before {
                display: none;
            }
        }
    }
}

// Nav 4th level item
.ms-nav__list__item .havesubmenu .drawer .collapse,
.ms-nav__list__item .havesubmenu .drawer .collapsing,
.ms-nav__list__item .havesubmenu .drawer .collapse.show {
    .ms-nav__list__item__link {
        position: relative;
        margin-left: 10px;
    }
}

.ms-nav__list__item .havesubmenu .drawer .collapse,
.ms-nav__list__item .havesubmenu .drawer .collapse.show {
    .ms-nav__list__item__link {
        &::after {
            content: "•";
            position: absolute;
            left: -17px;
            bottom: 50%;
            font-size: 24px;
            transform: translateY(50%);
        }
    }
}

// Navigation Image Fixed Size
$sgi-nav-image-width: 210px;
$sgi-nav-image-height: 280px;
.ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__feature>.category-image-container .category-image {
    height: $sgi-nav-image-height;
    max-height: $sgi-nav-image-height;
    width: $sgi-nav-image-width;
    max-width: $sgi-nav-image-width;
    margin: 0 auto;
    img {
        height: auto;
        max-height: 100%;
        width: auto;

    }
}