// @import "../common/00-settings/colors.scss";
// @import "../common/00-settings/icons.scss";
// @import "../common/00-settings/responsive-breakpoints.scss";
// @import "../common/00-settings/font.scss";
// @import "../common/01-tools/mixins.scss";
// @import "../common/02-generic/image.scss";
// @import "../common/02-generic/form.scss";

$ms-checkout-pickup-image-size: 108px;

.ms-checkout-pickup {
    &__group {
        margin-bottom: 20px;

        .msc-alert {
            clear: both;
        }

        &-divider {
            border: 1px dashed;
            border-color: $msv-gray-300;
            margin-bottom: 20px;
        }

        &-header {
            float: left;
            line-height: 29px;
            margin-bottom: 12px;

            &-main {
                @include add-icon($msv-roadster, before);

                &::before {
                    padding-right: 10px;
                }
                float: left;
                margin-right: 14px;

                @include font-heading-h5-l();
            }

            &-item {
                float: left;

                @include font-body-regular-s();
                color: $msv-gray-40;
                margin-top: 5px;
            }
        }

        &-lines {
            clear: both;
        }

        &-line-image {
            position: relative;
            border: 0.5px solid $msv-gray-50;
            box-sizing: border-box;
            transform: matrix(-1, 0, 0, 1, 0, 0);

            @include image(100%);
        }

        &-line-quantity {
            border-radius: 100px;
            border: 1px solid $msv-gray-50;
            position: absolute;
            bottom: 0;
            right: 3px;
            font-size: $msv-font-size-xs;
            line-height: $msv-line-height-xs;
            text-align: center;
            z-index: 1;
            background-color: $msv-blue-50;
            color: var(--msv-font-secondary-color);
            margin: 3px 0;
            padding: 2px 5px 0 5px;
        }

        &-product-image {
            float: left;
            position: relative;
            margin-top: 12px;
            margin-right: 12px;
            margin-bottom: 20px;

            @include image($ms-checkout-pickup-image-size);
        }

        &-product-image.product-image-wide {
            margin-right: 24px;
        }

        &-datetime {
            clear: both;

            @media (min-width: $msv-breakpoint-l) {
                display: flex;
            }

            .msc-alert {
                @include alert();
                margin-top: 15px;
                order: 3;
                display: flex;
                align-items: center;
            }

            &-dropdown {
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                @media (min-width: $msv-breakpoint-m) {
                    max-width: 398px;
                }
                width: 100%;
                padding-top: 15px;

                &-label {
                    @include form-input-label();
                }

                &-select {
                    display: flex;

                    @include font-body-regular-s();
                    height: 52px;
                    width: $msv-width-full;
                    border: 0.5px solid $msv-gray-50;
                    box-sizing: border-box;
                    border-radius: 3px;
                    padding: 16px;
                }
            }
        }

        &-selected {
            clear: both;
            padding-top: 10px;
            order: 3;

            &-title {
                @include font-heading-h5-l();
                padding-bottom: 24px;
                text-transform: capitalize;
            }

            &-content {
                @include font-body-regular-m();
            }
        }

        &-address {
            clear: both;
            display: block;
            padding: 10px 0 18px 0;
            flex: 1;
            order: 3;

            &-header {
                @include font-heading-h5-l();
                padding-bottom: 16px;
            }

            &-name {
                display: block;

                @include font-body-regular-m();
            }

            &-city,
            &-threeletterisoregionname {
                &::before {
                    content: "\A";
                    white-space: pre;
                }
            }

            &-street,
            &-city,
            &-state,
            &-county,
            &-zipcode,
            &-threeletterisoregionname {
                @include font-body-regular-m();
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__group-datetime-dropdown {
            clear: both;
            padding-right: unset;
            width: 100%;
        }

        &__group-datetime-dropdown:not(:first-child) {
            margin-top: 20px;
        }
    }
}
