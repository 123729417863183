.ms-search {
    .ms-search__autoSuggest {
        .ms-search__autoSuggest__product
            .msc-autoSuggest__productResults-items
            .msc-autoSuggest__productResults-no-results-content {
            .msc-autoSuggest__productResults-no-results-content-title {
                @include av-font(book, $font-size-18, 1.3);
            }
            .msc-autoSuggest__productResults-no-results-content-paragraph {
                @include av-font(book, $font-size-14, 1.43);
            }
        }
        .msc-autoSuggest__results-container {
            height: 75vh !important;
        }
        .ms-search__autoSuggest__product
            .msc-autoSuggest__productResults-items
            .msc-autoSuggest__productResults-no-results-content {
                margin-top: 0;
        }
        .msc-autoSuggest__search-title-container .msc-autoSuggest__search-title {
            @include media-breakpoint(md, max-width) {
                margin-top: 70px;
                margin-bottom: 30px;
            }
        }
    }

    .ms-search__form.bx-show .ms-search__searchForm .msc-autoSuggest__productResults-empty-mobile-content {
        .msc-autoSuggest__productResults-empty-mobile-heading {
            @include av-font(book, $font-size-18, 1.3);
        }
        .msc-autoSuggest__productResults-empty-mobile-text {
            @include av-font(book, $font-size-14, 1.43);
        }
    }

    //Mobile view search bar
    .ms-search__form.bx-show {
        .ms-search__form-control {
            @include media-breakpoint(md, max-width) {
                background-color: transparent;
                height: 80%;
                margin-top: 5px;
            }
        }
        .ms-search__form-submitSearch {
            @include media-breakpoint(lg, max-width) {
                background-color: transparent;
            }
        }
    }

    .ms-search__searchForm {
        @include media-breakpoint(md, max-width) {
            margin-top: 15px;
        }
    }
}

a.msc-autoSuggest__productResults-item .msc-autoSuggest__productResults-item-img {
    height: auto;
    width: 100%;
    max-height: 300px;
    max-width: 200px;
    object-fit: contain;
    object-position: bottom;
    &.msc-empty_image {
        @include image-empty-placeholder(150px, 150px);
        height: 150px;
        width: 150px;
        max-width: 100%;
        
    }
    
}