//=============================================================================//
// IMAGE LIST CAROUSEL
//===========================================================================//
$sgi-carousel-flipper-size: 48px;
.ms-image-list {
    .msc-ss-carousel {
        .msc-ss-carousel__flipper {
            height: $sgi-carousel-flipper-size !important;
            width: $sgi-carousel-flipper-size !important;
            .msi-chevron-right:before,
            .msi-chevron-left:before {
                font-size: ($sgi-carousel-flipper-size - 20) !important;
            }
            &.disabled {
                visibility: hidden;
                opacity: 0;
            }
            &[aria-label="Previous"] {
                left: -15px;
                top: 50%;
                @include media-breakpoint(lg) {
                    left: -40px;
                }
            }
            &[aria-label="Next"],
            .msc-ss-carousel__flipper--next {
                right: -15px;
                top: 50%;
                @include media-breakpoint(lg) {
                    right: -40px;
                }
            }
        }
        .msc-ss-carousel-slide {
            display: flex;
            align-items: flex-start;
            @include media-breakpoint(md) {
                justify-content: center;
            }
        }
    }
}

//=============================================================================//
// IMAGE LIST
//===========================================================================//
$sgi-image-list-size-xs: 200px;
$sgi-image-list-size: 160px;
.ms-image-list {
    .ms-image-list__list {
        margin: 30px;
        justify-content: center;
        align-items: flex-start;
        @include media-breakpoint(md) {
            margin: 50px;
        }
        &-item {
            a {
                min-width: $sgi-image-list-size-xs;
                width: $sgi-image-list-size-xs;
                max-width: $sgi-image-list-size-xs;
                margin-left: 20px;
                margin-right: 20px;
                @include media-breakpoint(sm) {
                    min-width: $sgi-image-list-size;
                    width: $sgi-image-list-size;
                    max-width: $sgi-image-list-size;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
    .ms-image-list__text {
        @include av-font(heavy, $font-size-14, 1.3);
        margin-top: 20px;
        text-align: center;
        white-space: break-spaces;
    }
}
