$sgi-font-path: "../../../webfonts/sgi";
@font-face {
    font-family: "icomoon";
    src: url("#{$sgi-font-path}/icomoon.woff?3n04jk") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-sgi-logo:before {
    content: "\e900";
}

$icon-sgi-logo: "\e900";

.icon-amazon-logo .path1:before {
    content: "\e901";
    color: #f90;
    display: inline-block;
}
.icon-amazon-logo .path2:before {
    content: "\e902";
    color: #f90;
    margin-left: -1em;
    display: inline-block;
}
.icon-amazon-logo .path3:before {
    content: "\e903";
    color: #fff;
    margin-left: -1em;
    display: inline-block;
}

@mixin add-icomoon($icon, $selector: before) {
    &:#{$selector} {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: $icon;
    }
}
