//==============================================//
// HUMAN REVOLUTION
//==============================================//
.msc-active-image {
    &.msc-active-image__revolution {
        border-radius: 10px;
        .msc-active-image__details {
            position: absolute;
            top: 0;
            margin-top: 30px;
            margin-bottom: 0;
            align-items: center;
            @include media-breakpoint(lg) {
                margin-top: 0;
            }
            &__title {
                margin-bottom: 15px;
                display: block;
                font-size: $font-size-32;
                text-align: center;
                @include media-breakpoint(sm) {
                    margin-bottom: 60px;
                }
                @include media-breakpoint(xl) {
                    margin-bottom: 15px;
                }
                @media screen and (min-width: $breakpoint-1440) {
                    margin-bottom: 60px;
                }
                // Dark theme override
                @at-root .texttheme__dark#{&} {
                    color: $color-primary-black;
                }
            }
            &__text {
                @include av-font(black, $font-size-16, 1.8);
                text-align: center;
                margin-top: 15px;
                margin-left: auto;
                margin-right: auto;
                text-shadow: 1px 1px 3px transparentize($color: $color-primary-black, $amount: 0.3);
                @include media-breakpoint(sm) {
                    margin-top: 50px;
                }
                @include media-breakpoint(md) {
                    max-width: 75%;
                }
                @include media-breakpoint(xl) {
                    max-width: 41%;
                    margin-top: 0;
                }
                @media screen and (min-width: $breakpoint-1440) {
                    margin-top: 50px;
                }
                @at-root .texttheme__dark#{&} {
                    color: $color-primary-white;
                }
            }
            &__additional-content {
                @include media-breakpoint(lg) {
                    margin-top: 0;
                }
                &__container {
                    justify-content: center;
                }
            }
            &__cta__link {
                padding-left: 15px;
                padding-right: 15px;
                @media screen and (min-width: ($breakpoint-475 - 100)) {
                    padding-left: 48px;
                    padding-right: 48px;
                }
            }
        }
        .msc-active-image-container {
            display: flex;
            justify-content: center;
            img {
                object-fit: cover;
                max-width: none;
                width: auto;
                min-height: 750px;
                @include media-breakpoint(xs) {
                    min-height: 650px;
                }
                @media (min-width: ($breakpoint-475 - 100)) {
                    min-height: auto;
                }
                @include media-breakpoint(xl) {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
}
