.ms-footer {
    .post-footer-container {
        .ms-footer-logo {
            img {
                max-width: 80px;
                max-height: 80px;
            }
        }
    }
}
