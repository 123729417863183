// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
$button-type: (
    primary: (
        border: 1px solid $color-primary-white,
        color: $color-primary-white,
        background-color: $color-primary-black,
    ),
    secondary: (
        border: 1px solid $color-primary-black,
        color: $color-primary-black,
        background-color: $color-primary-white,
    ),
);

$button-size: (
    default: (
        padding: 12px 48px,
        font-size: $font-size-14,
        line-height: 1.43,
    ),
);

//function to get deep level mapped keys
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }

    @return $map;
}

@mixin btn($btn-type, $btn-size) {
    position: relative;
    height: 44px;
    width: 100%;
    min-width: 165px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    letter-spacing: 0.1em;
    font-style: normal;
    padding: map-deep-get($button-size, $btn-size, padding);
    color: map-deep-get($button-type, $btn-type, color);
    background-color: map-deep-get($button-type, $btn-type, background-color);
    border: map-deep-get($button-type, $btn-type, border);
    font-size: map-deep-get($button-size, $btn-size, font-size);
    line-height: map-deep-get($button-size, $btn-size, line-height);
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    outline: 0;
    outline-offset: -6px;
    border-radius: 100px;
    text-transform: uppercase;
    box-sizing: border-box;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        text-decoration: none;
        @if ($btn-type==primary) {
            background-color: #000;
            color: $color-primary-white;
        } @else if ($btn-type==secondary) {
            background-color: $color-primary-black;
            color: $color-primary-white;
            border-color: $color-primary-black;
        }
    }

    &:focus-visible {
        text-decoration: none;
        outline-width: 1px;
        outline-style: dashed;
        @if ($btn-type==primary) {
            color: $color-primary-white;
            outline-color: $color-primary-white;
        } @else if ($btn-type==secondary) {
            color: $color-primary-black;
            outline-color: $color-primary-black;
        }
    }
}

// .msc-cta__primary {
//     @include btn(primary, default);
// }

// .msc-cta__secondary {
//     @include btn(secondary, default);
// }
