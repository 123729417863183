//==============================================//
// LOGO                                         //
//==============================================//

.ms-header {
    .ms-header__container .ms-header__topbar .ms-header__logo img,
    .ms-header__container .ms-header__topbar .ms-header__mobile-logo img {
        width: auto;
        height: 100%;
        min-width: auto;
        min-height: auto;
    }
    .ms-header__logo {
        width: 210px;
        height: 80px;
        a {
            width: 210px;
            height: 80px;
        }
        @include media-breakpoint(xl) {
            width: 260px;
            height: 80px;
            a {
                width: 260px;
                height: 80px;
            }
        }
    }
}

.ms-header .ms-header__container .ms-header__topbar {
    .ms-header__mobile-logo {
        width: 60px;
        height: 60px;
        a {
            width: 60px;
            height: 60px;
        }
        @include media-breakpoint(xs, max-width) {
            display: none;
        }
    }
    .ms-header__nav-icon {
        @media (max-width: 767px) {
            margin-right: 10px;
        }
        margin-right: 45px;
        &:before {
            font-size: 30px;
        }
    }

}