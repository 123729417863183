.ms-tile-list {
    text-align: center;
}
.ms-tile-list .msc-ss-carousel .ms-tile__item .ms-tile-list__heading .ms-tile-list__title {
    font-size: ($font-size-18 + 0.25);
}
.ms-tile-list
    .msc-ss-carousel
    .ms-tile__item
    .ms-tile-list-container:hover
    .ms-tile-list__heading
    .ms-tile-list__title {
    font-size: $font-size-24;
}

.ms-tile-list .msc-ss-carousel .ms-tile__item {
    @include media-breakpoint(lg) {
        width: 300px;
    }
    &:last-of-type {
        margin-right: 0;
    }
    .ms-tile-list-container {
        @include media-breakpoint(lg) {
            width: 300px;
        }
    }
}
