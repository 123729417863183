.ms-buybox {
    //=============================================================================//
    // SGI BUYBOX - PRODUCT ATTRIBUTES
    //===========================================================================//

    // ABOUT

    .about-section {
        padding-top: 15px !important;
    }
}

.amazon-buy {
    @include btn(primary, default);
    height: 56px;
    line-height: 2.3;
    margin-top: 30px;
    .ms-quickView__product-add-to-cart & {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .icon-amazon-logo {
        font-size: 64px;
        line-height: 0.6;
        margin-left: 5px;
        height: 24px;
        span {
            display: inline-block;
            height: 24px;
        }
    }
}