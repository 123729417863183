// @import "../common/00-settings/colors.scss";
// @import "../common/00-settings/font.scss";
// @import "../common/00-settings/responsive-breakpoints.scss";
// @import "../common/00-settings/icons.scss";
// @import "../common/01-tools/mixins.scss";
// @import "../common/02-generic/image.scss";
// @import "../common/02-generic/button.scss";

$navigation-parent-menu-margin-left: 20px;
$navigation-parent-menu-margin-right: 20px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-menu-item-line-height: 28px;
$navigation-menu-item-level2-line-height: 24px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-padding: 40px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 31px;
$navigation-ms-nav-area-width: 63%;
$navigation-menu-list-min-width: 180px;
$navigation-ms-nav-list-item-link-margin-bottom: 20px;

$navigation-ms-nav-list-mobile-container-padding-left: 16px;
$navigation-ms-nav-list-mobile-container-padding-right: 34px;
$navigation-ms-nav-list-button-mobile-padding-left: 0;
$navigation-ms-nav-list-button-mobile-padding-right: 0;
$navigation-ms-nav-list-button-before-mobile-font-size: 21px;
$navigation-ms-nav-list-item-link-mobile-margin-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding: 16px;
$navigation-ms-nav-list-feature-mobile-padding-bottom: 20px;
$navigation-ms-nav-child-mobile-padding-top: 12px;

$navigation-ms-nav-list-tablet-margin-left: 0;
$navigation-ms-nav-list-item-tablet-margin-left: 0;
$navigation-ms-nav-list-level3-tablet-margin-left: 20px;
$navigation-ms-nav-ms-nav-list-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-link-level2-tablet-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level3-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-tablet-first-child-margin-left: 0;
$navigation-ms-nav-close-button-tablet-padding-right: 16px;
$navigation-ms-nav-close-button-tablet-padding-top: 10px;
$navigation-ms-nav-list-item-link-button-tablet-margin-bottom: 28px;
$navigation-ms-nav-list-item-nav-feature-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left: 40px;
$navigation-ms-nav-list-item-link-tablet-padding-zero: 0;
$navigation-ms-nav-list-item-button-first-child-tablet-margin-left: 0;
$navigation-ms-nav-drawer-open-close-tablet-padding: 13px;

$navigation-ms-nav-list-desktop-margin-left: 0;
$navigation-ms-nav-list-item-desktop-margin-left: 0;
$navigation-ms-nav-list-level2-desktop-margin-left: 20px;
$navigation-ms-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-link-desktop-margin-bottom: 25px;
$navigation-ms-nav-list-item-link-level2-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-button-link-desktop-margin-bottom: 40px;
$navigation-ms-nav-list-item-first-child-desktop-margin-left: 0;
$navigation-ms-nav-default-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-right: 60px;
$navigation-ms-nav-list-item-levle2-desktop-padding-right: 20px;
$navigation-ms-nav-list-item-drawer-button-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-desktop-padding-left: 40px;
$navigation-ms-nav-list-item-link-desktop-padding-left: 0;
$navigation-ms-nav-list-item-link-desktop-padding-right: 0;
$navigation-ms-nav-list-item-button-first-child-desktop-margin-left: 0;
$navigation-ms-nav-drawer-open-desktop-padding-right: 13px;
$navigation-ms-nav-drawer-close-desktop-padding-right: 13px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-right: 20px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom: 25px;

$msv-width-onequarter: 25%;

//style presets
:root {
    --msv-nav-bg: var(--msv-accent-brand-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-secondary-color);
    --msv-nav-font-color: var(--msv-font-secondary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-close-icon-font-size: #{$msv-icon-size-xs};
}

.ms-nav {
    background: var(--msv-nav-bg);
    color: var(--msv-nav-font-color);
    width: $msv-width-full;
    padding-left: 0;
    padding-right: 0;

    &__list {
        z-index: 1000;

        &.level-2 {
            width: $msv-width-full;
            overflow-y: auto;
        }

        .level-2 {
            > li {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            &.navmenu-multi-level {
                li.ms-nav__list__item {
                    .havesubmenu {
                        font-weight: normal;
                    }
                }
            }
        }

        &__item {
            @include font-body-regular-m();
            color: var(--msv-nav-font-color);

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .havesubmenu {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);

                .drawer {
                    .drawer__button {
                        margin-bottom: 15px;
                        border-bottom: none;
                        border: none;
                        color: var(--msv-nav-font-color);
                    }

                    .collapse.show {
                        display: grid;
                        margin-left: 10px;

                        > .ms-nav__list__item__link {
                            padding-left: 10px;
                            cursor: pointer;
                        }

                        .ms-nav__list__item__link {
                            margin-left: $navigation-ms-nav-submenu-margin-left;
                            display: block;
                        }
                    }

                    .collapsing {
                        display: grid;
                        margin-left: 10px;

                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        .ms-nav__list__item__link {
                            margin-left: $navigation-ms-nav-submenu-margin-left;
                            display: block;
                        }
                    }
                }
            }

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    > .ms-nav__list {
        background: var(--msv-nav-bg);

        > .ms-nav__list__item {
            background: var(--msv-nav-bg);

            > .ms-nav__list {
                background: var(--msv-nav-bg);
                min-width: $navigation-menu-list-min-width;
            }

            .ms-nav__deafult {
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                }
            }

            > .ms-nav__deafult {
                left: 0;
            }

            > .ms-nav__feature {
                background: var(--msv-nav-bg);
                border: 1px solid var(--msv-nav-dropdown-border);
                height: auto;
                left: $navigation-menu-wrapper-left;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                overflow: auto;
                width: $navigation-menu-wrapper-width;
                display: inline-flex;

                > .category-image-container {
                    .category-image {
                        position: relative;
                        margin-right: 3px;

                        .category-image-title {
                            text-align: center;
                            display: none;
                        }

                        picture {
                            + div.category-image-title {
                                display: block;
                            }
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-dark-m();
                            text-align: center;
                            word-break: break-all;
                            white-space: break-spaces;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            padding: 0;
                            border-bottom: 3px solid $msv-blue;
                            transform: translate(-50%, -50%);
                            background-color: transparent;
                        }

                        img {
                            margin: 0 auto;
                            margin-bottom: $navigation-ms-nav-list-item-link-margin-bottom;

                            > .ms-nav-image__item {
                                display: block;
                                padding: $navigation-menu-image-padding;
                            }

                            &:hover {
                                transform: scale(1.025);
                                transition: transform 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;

            &__mobile__container {
                border-bottom: 1px solid var(--msv-nav-border);
                display: flex;
                padding-left: $navigation-ms-nav-list-mobile-container-padding-left;
                padding-right: $navigation-ms-nav-list-mobile-container-padding-right;

                &__button {
                    @include vfi();
                    @include add-icon($msv-BackArrow);
                    @include font-body-regular-m();
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    padding-left: $navigation-ms-nav-list-button-mobile-padding-left;
                    padding-right: $navigation-ms-nav-list-button-mobile-padding-right;

                    &:focus {
                        outline-offset: -1px;
                    }
                }

                &__button::before {
                    font-size: $navigation-ms-nav-list-button-before-mobile-font-size;
                }

                &__span {
                    @include font-body-bold-m();
                    margin: 0 auto;
                    color: var(--msv-nav-font-color);
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                &__item {
                    &__image {
                        display: none;
                    }

                    &__button,
                    &__link,
                    &__span {
                        @include font-heading-h4-s();
                        display: flex;
                        align-items: center;
                        color: var(--msv-header-font-color);
                        height: auto;

                        @media screen and (max-width: (($msv-breakpoint-m) - 1)) {
                            margin-top: $navigation-ms-nav-list-item-link-mobile-margin-top;
                            width: 100%;
                            padding: $navigation-ms-nav-list-item-link-mobile-padding;
                        }
                        background-color: var(--msv-nav-bg);
                        border: none;
                        padding: 0;

                        &::after {
                            display: none;
                        }

                        &:focus,
                        &:active {
                            @include font-heading-h3-s();
                            outline: none;
                            background-color: $msv-gray-70;
                        }
                    }

                    .ms-nav__list {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: (($msv-breakpoint-m) - 1)) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();
                            color: var(--msv-nav-font-color);

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                color: var(--msv-nav-font-color);
                                outline: none;
                                background-color: $msv-gray-70;
                            }
                        }
                    }

                    .ms-nav__list.level-3 {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: (($msv-breakpoint-m) - 1)) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                outline: none;
                                background-color: $msv-gray-70;
                            }
                        }
                    }
                }
            }
        }

        > .ms-nav__list {
            width: $msv-width-full;
            outline: none;

            .ms-nav__list {
                width: $msv-width-full;
            }

            .havecateImage {
                float: left;
                position: relative;
                width: $navigation-ms-nav-area-width;
            }

            > .ms-nav__list__item {
                .ms-nav__feature {
                    padding-bottom: $navigation-ms-nav-list-feature-mobile-padding-bottom;
                    width: $msv-width-full;
                }

                > .ms-nav__list__item__button {
                    color: var(--msv-nav-font-color);
                    justify-content: space-between;

                    @media screen and (max-width: (($msv-breakpoint-m) - 1)) {
                        @include add-icon($msv-ChevronRight, after);
                        float: right;
                    }
                }
            }

            .ms-nav__list__item:first-child {
                @media screen and (max-width: (($msv-breakpoint-m) - 1)) {
                    padding-top: $navigation-ms-nav-list-item-link-mobile-padding-top;
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        top: 0;
        padding-top: $navigation-ms-nav-child-mobile-padding-top;
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-nav {
        display: flex;
        position: relative;
        border-top: 1px solid var(--msv-header-font-color);

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: $msv-width-threequarter;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-tablet-margin-left;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: $navigation-ms-nav-list-item-tablet-margin-left;

                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level3-tablet-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }

        .category-image-container {
            width: $msv-width-onequarter;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list {
            display: flex;
            flex-wrap: nowrap;
            outline: none;
            position: relative;
            width: $navigation-ms-nav-list-width;
            padding-left: $navigation-ms-nav-ms-nav-list-tablet-padding-left;
            overflow-x: auto;
            height: 70vh;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .ms-nav__list {
                height: auto;

                .ms-nav__list__item__link {
                    @include font-body-regular-m();
                    color: var(--msv-nav-font-color);
                    margin-bottom: $navigation-ms-nav-list-item-link-level2-tablet-margin-bottom;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        bottom: -8px;
                        left: 0;
                        background-color: $msv-blue;
                        visibility: visible;
                        transform: scaleX(1);
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover::before {
                        visibility: visible;
                        transform: scaleX(1);
                        bottom: -4px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list {
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-bottom: $navigation-ms-nav-list-item-link-level3-tablet-margin-bottom;
                        border-bottom: none;

                        &::before {
                            visibility: hidden;
                        }

                        &:hover::before {
                            visibility: hidden;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .ms-nav__list__item:first-child {
                margin-left: $navigation-ms-nav-list-item-tablet-first-child-margin-left;
            }

            .ms-nav-close-button {
                width: 100%;
                text-align: right;
                right: 0;
                padding-right: $navigation-ms-nav-close-button-tablet-padding-right;
                padding-top: $navigation-ms-nav-close-button-tablet-padding-top;

                .nav-menu-close {
                    color: var(--msv-nav-font-color);
                    background-color: transparent;
                    border: 1px solid transparent;

                    @include vfi();

                    &::before {
                        @include msv-icon();
                        content: $msv-Cancel;
                        font-size: var(--msv-nav-close-icon-font-size);
                        position: unset;
                        text-align: center;
                        vertical-align: text-bottom;
                    }
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-body-regular-m();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-link-button-tablet-margin-bottom;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        @include font-heading-h3-m();
                        cursor: pointer;
                    }
                }

                > .ms-nav__list__item__button[aria-expanded="true"],
                > .ms-nav__list__item__link[aria-expanded="true"] {
                    @include font-heading-h3-m();
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button {
                    &::after {
                        @include msv-icon();
                        color: var(--msv-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--msv-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        line-height: $navigation-menu-icon-line-height;
                        display: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list__item__link {
                    color: var(--msv-nav-font-color);
                    justify-content: flex-start;
                    width: auto;
                    display: inline-block;
                }

                > .ms-nav__feature,
                > .ms-nav__deafult {
                    > .ms-nav__list {
                        width: $msv-width-threequarter;
                        display: flex;
                        justify-content: flex-start;
                        left: 0;
                        background: var(--msv-nav-bg);

                        > .ms-nav__list__item {
                            width: 33.3%;
                            display: inline-block;

                            > .ms-nav__list__item__link {
                                @include feature-link-dark-m();
                            }
                        }
                    }
                }

                > .ms-nav__deafult {
                    > .ms-nav__list {
                        width: $msv-width-full;
                        padding-left: $navigation-ms-nav-list-item-nav-feature-tablet-padding-left;

                        > .ms-nav__list__item {
                            padding-bottom: 25px;
                        }
                    }
                }

                > .ms-nav__feature {
                    display: inline-flex;
                    padding-left: $navigation-ms-nav-list-item-nav-feature-tablet-padding-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    padding-right: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                        text-decoration: none;
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &::before {
                            width: $msv-width-full;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: $navigation-ms-nav-list-item-button-first-child-tablet-margin-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                    }
                }
            }
        }

        &.ms-nav__disable-top-menu {
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list > .ms-nav__list__item {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-heading-h4-m();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-link-button-tablet-margin-bottom;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        @include font-heading-h3-m();
                        cursor: pointer;
                    }
                }

                > .ms-nav__list__item__button[aria-expanded="true"],
                > .ms-nav__list__item__link[aria-expanded="true"] {
                    @include font-heading-h3-m();
                }

                > .ms-nav__feature {
                    > .ms-nav__list > .ms-nav__list__item {
                        width: auto;
                        padding-right: $navigation-ms-nav-list-item-link-disable-top-menu-padding-right;
                        padding-bottom: $navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom;

                        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                            width: 33.3%;
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-m();
                            height: auto;
                            letter-spacing: normal;

                            &::before {
                                border-radius: 10px;
                            }
                        }

                        &.havesubmenu .ms-nav__deafult {
                            .ms-nav__list {
                                .ms-nav__list__item__link {
                                    @include font-body-regular-m();
                                    color: var(--msv-nav-font-color);
                                    margin-bottom: $navigation-ms-nav-list-item-link-level2-desktop-margin-bottom;
                                    border-bottom: none;

                                    @include vfi();

                                    &::before {
                                        visibility: hidden;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }

                                    &:hover::before {
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .drawer__glyph:focus {
        outline: none;
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        display: flex;

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: 80%;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-desktop-margin-left;

                    .ms-nav__list__item {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-left: $navigation-ms-nav-list-item-desktop-margin-left;

                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level2-desktop-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }

        .category-image-container {
            width: 20%;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list {
            display: flex;
            flex-wrap: nowrap;
            outline: none;
            position: relative;
            width: $navigation-ms-nav-list-width;
            padding-left: $navigation-ms-nav-list-desktop-padding-left;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .ms-nav__list {
                height: auto;

                .ms-nav__list__item__link {
                    @include font-body-regular-m();
                    color: var(--msv-nav-font-color);
                    margin-bottom: $navigation-ms-nav-list-item-link-desktop-margin-bottom;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        bottom: -8px;
                        left: 0;
                        background-color: $msv-blue;
                        visibility: visible;
                        transform: scaleX(1);
                        transition: all 0.2s ease-in-out;
                    }

                    &:hover::before {
                        visibility: visible;
                        transform: scaleX(1);
                        bottom: -4px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list {
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-bottom: $navigation-ms-nav-list-item-link-level2-desktop-margin-bottom;
                        border-bottom: none;

                        @include vfi();

                        &::before {
                            visibility: hidden;
                        }

                        &:hover::before {
                            visibility: hidden;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .ms-nav__list__item:first-child {
                margin-left: $navigation-ms-nav-list-item-first-child-desktop-margin-left;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-heading-h4-l();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    line-height: $navigation-menu-item-line-height;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-button-link-desktop-margin-bottom;
                    padding: 0;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &::before {
                        right: 0;
                        left: 0;
                        content: "";
                        outline-offset: 0;
                        width: 0%;
                        visibility: hidden;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover {
                        cursor: pointer;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button {
                    &::after {
                        @include msv-icon();
                        color: var(--msv-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--msv-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        line-height: $navigation-menu-icon-line-height;
                        display: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list__item__link {
                    color: var(--msv-nav-font-color);
                    justify-content: flex-start;
                    width: auto;
                    display: inline-block;
                }

                > .ms-nav__deafult {
                    > .ms-nav__list {
                        padding-left: $navigation-ms-nav-default-nav-list-desktop-padding-left;

                        > .ms-nav__list__item {
                            width: 20%;
                        }
                    }
                }

                > .ms-nav__feature {
                    padding-left: $navigation-ms-nav-list-item-nav-feature-desktop-padding-left;
                    display: inline-flex;
                    padding-right: $navigation-ms-nav-list-item-nav-feature-desktop-padding-right;
                }

                > .ms-nav__feature,
                > .ms-nav__deafult {
                    > .ms-nav__list {
                        > .ms-nav__list__item {
                            width: 20%;
                            display: inline-block;
                            padding-right: $navigation-ms-nav-list-item-levle2-desktop-padding-right;

                            > .ms-nav__list__item__link {
                                @include feature-link-dark-m();
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: $navigation-ms-nav-list-item-link-desktop-padding-left;
                    padding-right: $navigation-ms-nav-list-item-link-desktop-padding-right;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                        text-decoration: none;
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &::before {
                            width: $msv-width-full;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: $navigation-ms-nav-list-item-button-first-child-desktop-margin-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                    }
                }
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-desktop-padding-right;
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-close-desktop-padding-right;
    }

    .drawer__glyph:focus {
        outline: none;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .ms-nav {
        display: flex;
    }
}
