// @import "../common/00-settings/colors.scss";
// @import "../common/00-settings/font.scss";
// @import "../common/00-settings/responsive-breakpoints.scss";
// @import "../common/02-generic/image.scss";
// @import "../common/01-tools/layout.scss";
// @import "../common/01-tools/mixins.scss";
// @import "../common/02-generic/button.scss";
// @import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";

$msc-content-block-slim-hero-large-cta-margin-top: 24px;
$msc-content-block-slim-hero-large-desktop-content-margin-left: 64px;
$msc-content-block-slim-hero-large-tablet-content-margin-left: 45px;
$msc-content-block-slim-hero-large-mobile-content-margin-left: 28px;

$msc-content-block-slim-hero-small-desktop-content-margin-left: 64px;
$msc-content-block-slim-hero-small-desktop-content-margin-right: 58px;
$msc-content-block-slim-hero-small-tablet-content-margin-left: 45px;
$msc-content-block-slim-hero-small-mobile-content-margin-left: 28px;

$ms-content-block__details-slim-hero-medium-margin-left: 64px;
$ms-content-block__details-slim-hero-medium-margin-left-tablet: 45px;
$ms-content-block__details-slim-hero-medium-margin-left-mobile: 28px;
$ms-content-block__details-title-slim-hero-medium-margin-top-mobile: 32px;
$ms-content-block__details-text-slim-hero-medium-margin-top: 12px;
$ms-content-block__details-text-slim-hero-medium-max-width: 28.3em;
$ms-content-block__details-text-slim-hero-medium-max-width-tablet: 27em;
$ms-content-block__details-text-slim-hero-medium-max-width-mobile: 18.5em;
$ms-content-block__details-cta-slim-hero-medium-right: 58px;
$ms-content-block__details-cta-slim-hero-medium-margin-top-tablet: 16px;
$ms-content-block__details-cta-slim-hero-medium-margin-top-mobile: 26px;

$ms-content-block__details-title-slim-hero-max-width: 65%;
$ms-content-block__details-cta-slim-hero-max-width: 28%;

:root {
    // heading
    --msv-content-block-slim-hero-heading-font-color: #{$msv-white};

    // Text theme
    --msv-content-block-font-color: #{$msv-white};
}

.ms-content-block {
    &[data-m-layout="slim-hero-large"] {
        a {
            border: none;
        }

        .ms-content-block__link,
        .ms-content-block__image {
            width: $msv-width-full;
        }

        @include image($width: $msv-width-full);

        .ms-content-block__details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;

            .ms-content-block__title {
                @include font-heading-h1-l();
                color: var(--msv-content-block-slim-hero-heading-font-color);
                overflow: hidden;
            }

            .ms-content-block__text {
                @include font-body-regular-m();
                color: var(--msv-content-block-font-color);
                max-width: 28em;
                margin-top: 16px;
                overflow: hidden;
            }

            .ms-content-block__cta {
                margin-top: $msc-content-block-slim-hero-large-cta-margin-top;

                .msc-cta__primary {
                    @include secondary-button-light();
                }
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                margin-left: $msc-content-block-slim-hero-large-desktop-content-margin-left;
            }

            @media (max-width: $msv-breakpoint-m) {
                margin-left: $msc-content-block-slim-hero-large-mobile-content-margin-left;

                .ms-content-block__title {
                    @include font-heading-h2-s();
                    color: var(--msv-content-block-slim-hero-heading-font-color);
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-left: $msc-content-block-slim-hero-large-tablet-content-margin-left;

                .ms-content-block__title {
                    @include font-heading-h1-m();
                    color: var(--msv-content-block-slim-hero-heading-font-color);
                }
            }
        }
    }

    &[data-m-layout="slim-hero-medium"] {
        position: relative;

        a {
            border: none;
        }

        .ms-content-block__link,
        .ms-content-block__image {
            width: $msv-width-full;
        }

        @include image($width: $msv-width-full);

        .ms-content-block__details {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: $ms-content-block__details-slim-hero-medium-margin-left;
            overflow: hidden;

            .ms-content-block__title {
                @include font-heading-h1-l();
                color: var(--msv-content-block-slim-hero-heading-font-color);
                overflow: hidden;
            }

            .ms-content-block__text {
                @include font-body-regular-m();
                color: var(--msv-content-block-font-color);
                margin-top: $ms-content-block__details-text-slim-hero-medium-margin-top;
                overflow: hidden;
                max-width: $ms-content-block__details-text-slim-hero-medium-max-width;
            }

            .ms-content-block__cta {
                position: absolute;
                right: $ms-content-block__details-cta-slim-hero-medium-right;

                .msc-cta__primary {
                    @include secondary-button-light();
                }
            }

            @media (min-width: $msv-breakpoint-l) {
                .ms-content-block__title {
                    max-width: $ms-content-block__details-title-slim-hero-max-width;
                }

                .ms-content-block__cta {
                    max-width: $ms-content-block__details-cta-slim-hero-max-width;
                }
            }

            @media (max-width: $msv-breakpoint-m) {
                margin-left: $ms-content-block__details-slim-hero-medium-margin-left-mobile;

                .ms-content-block__title {
                    @include font-heading-h3-s();
                    margin-top: $ms-content-block__details-title-slim-hero-medium-margin-top-mobile;
                }

                .ms-content-block__text {
                    max-width: $ms-content-block__details-text-slim-hero-medium-max-width-mobile;
                }

                .ms-content-block__cta {
                    position: static;
                    margin-top: $ms-content-block__details-cta-slim-hero-medium-margin-top-mobile;
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l - 1) {
                margin-left: $ms-content-block__details-slim-hero-medium-margin-left-tablet;
                height: auto;
                max-height: 172px;

                .ms-content-block__title {
                    @include font-heading-h1-m();
                    margin-top: $ms-content-block__details-title-slim-hero-medium-margin-top-mobile;
                }

                .ms-content-block__text {
                    max-width: $ms-content-block__details-text-slim-hero-medium-max-width-tablet;
                }

                .ms-content-block__cta {
                    position: static;
                    margin-top: $ms-content-block__details-cta-slim-hero-medium-margin-top-tablet;
                }
            }
        }
    }

    &[data-m-layout="slim-hero-small"] {
        a {
            border: none;
        }

        .ms-content-block__link,
        .ms-content-block__image {
            width: $msv-width-full;
        }

        @include image($width: $msv-width-full);

        .ms-content-block__details {
            display: flex;
            align-items: center;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;

            .ms-content-block__title {
                @include font-heading-h1-l();
                color: var(--msv-content-block-slim-hero-heading-font-color);
                overflow: hidden;
            }

            .ms-content-block__text {
                display: none;
            }

            .ms-content-block__cta {
                margin-left: auto;

                .msc-cta__primary {
                    @include secondary-button-light();
                }
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                margin-left: $msc-content-block-slim-hero-small-desktop-content-margin-left;
                margin-right: $msc-content-block-slim-hero-small-desktop-content-margin-right;
            }

            @media (max-width: $msv-breakpoint-m) {
                margin-left: $msc-content-block-slim-hero-small-mobile-content-margin-left;

                .ms-content-block__title {
                    @include font-heading-h2-s();
                    color: var(--msv-content-block-slim-hero-heading-font-color);
                }

                .ms-content-block__text,
                .ms-content-block__cta {
                    display: none;
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-left: $msc-content-block-slim-hero-small-tablet-content-margin-left;

                .ms-content-block__title {
                    @include font-heading-h1-m();
                    color: var(--msv-content-block-slim-hero-heading-font-color);
                }

                .ms-content-block__text,
                .ms-content-block__cta {
                    display: none;
                }
            }
        }
    }
}
