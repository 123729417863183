$subheader-height: 200px;
.ms-content-block[data-m-layout="slim-hero-small"],
.ms-content-block[data-m-layout="slim-hero-medium"] {
    min-height: $subheader-height;
    .ms-content-block__link,
    .ms-content-block__image {
        height: $subheader-height;
    }
}
.ms-content-block[data-m-layout="slim-hero-medium"] {
    .ms-content-block__details {
        @media screen and (min-width: $breakpoint-768) and (max-width: ($breakpoint-992 - 1)) {
            margin-right: 45px;
            .ms-content-block__title {
                margin-right: 45px;
                padding-bottom: 25px;
                font-size: var(--msv-h2-font-size-m);
            }
        }
        @include media-breakpoint(md, max-width) {
            margin-right: 28px;
            .ms-content-block__title {
                font-size: var(--msv-h2-font-size-s);
                padding-bottom: 30px;
            }
            .ms-content-block__cta {
                margin-top: 0;
            }
        }
        @include media-breakpoint(sm, max-width) {
            .ms-content-block__title {
                font-size: var(--msv-h4-font-size-s);
                padding-bottom: 15px;
            }
        }
    }
}
