.container-heading-aligned .container-heading {
    width: 100%;
    padding: 30px 30px 30px 0;
    line-height: 1.3;
    text-align: center;
}

@media only screen and (min-width: 1025px) {
    .container-heading-aligned .container-heading {
        margin-bottom: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
}


.error-page .ms-content-block {border: 1px solid #ccc}
.error-page .ms-content-block__details {padding: 20px 0 20px 0}

@media only screen and (min-width: 1025px) {
    .error-page .ms-content-block__details {padding: 32px 0 32px 0}
}

// checkout > Address > Name [Name should show in single line]
span.msc-address-detail__item.msc-address-detail__item-name{
    display: block;
}

/* // making all the EA as capitalize */
.msc-product__unit-of-measure span,
.ms-quickView__product-unit-of-measure span,
.msc-cart-line__product-unit-of-measure span,
.ms-buybox__product-unit-of-measure {text-transform: lowercase}

// .msc-product__title_description .msc-product__text {
//     white-space: pre-wrap;
// }

.ms-content-block__details__additional-content__paragraph {
    font-family: "Rubik-Bold" !important;
    margin-top: 31px;
    
}

@media only screen and (min-width: 1025px) {
    .ms-content-block__details__additional-content__paragraph {
        margin-top: 59px;
    }
}

@media only screen and (max-width: 1024px) {
    .ms-content-block__details__additional-content__paragraph {
        margin-top: 31px !important;
    }
}

// Home page > Altar color change
.sgi-content-block__altar-essentials .altar-essentials-details.ms-content-block {
    background-color: #6d8fdb;
}

.sgi-content-block__altar-essentials .ms-product-collection[data-p-layout="horizontal"] .msc-product__details{
    width: 130%
}

@media only screen and (min-width: 992px) {
    .sgi-content-block__altar-essentials .ms-product-collection[data-p-layout="horizontal"] .msc-product__details{
        width: 84%
    }
}

.sgi-address-errors {
    background: #ffeded;
    padding: 15px 25px;
    font-size: 15px;
}