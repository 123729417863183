// @import "../common/00-settings/colors.scss";
// @import "../common/00-settings/font.scss";
// @import "../common/00-settings/responsive-breakpoints.scss";
// @import "../common/01-tools/mixins.scss";
// @import "../common/01-tools/layout.scss";
// @import "../common/02-generic/image.scss";
// @import "../common/02-generic/button.scss";
// @import "../common/00-settings/icons.scss";
// @import "../common/02-generic/reset.scss";
// @import "../common/01-tools/bootstrap.scss";
// @import "../common/02-generic/form.scss";

$msv-account-profile-toggle-button-icon-size: 36px;
$msv-account-profile-toggle-button-padding: 0 5px;
$msv-account-profile-toggle-color: $msv-blue;
$msv-account-profile-links-padding: 6px 12px 6px 0;
$msv-account-profile-heading-padding-bottom: 24px;
$msv-account-profile-heading-margin-bottom: 44px;
$msv-account-profile-section-margin-bottom: 48px;
$msv-account-profile-section-heading-margin-bottom: 16px;
$msv-account-profile-section-preferences-margin-top: 41px;
$msv-account-profile-section-preferences-heading-margin-bottom: 38px;
$msv-account-profile-section-preferences-heading-padding-bottom: 22px;
$msv-account-profile-section-preferences-toggle-margin-top: 11px;
$msv-account-profile-section-preferences-web-tracking-margin-top: 48px;
$msv-account-profile-attributes-section-margin-bottom: 44px;
$msv-account-profile-attributes-section-save-button-margin-right: 12px;
$msv-account-profile-margin-right: 53px;
$msv-account-profile-cancel-button-margin-top: 15px;
$msv-account-profile-description-margin-bottom: 30px;
$msv-account-profile-vat-margin-bottom: 30px;

// style presets
:root {
    // title
    --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);
}

.ms-account-profile {
    display: inline-flex;
    width: $msv-width-full;

    @media screen and (min-width: $msv-breakpoint-m) {
        display: inline-flex;
    }

    .ms-account-profile-wrapper {
        margin-right: $msv-account-profile-margin-right;
        width: calc(5 * #{$msv-column-width-s});

        @media screen and (max-width: $msv-breakpoint-m) {
            padding-bottom: 40px;
        }

        .ms-account-loyalty-tile__heading {
            @include font-heading-h3-l();
            padding-bottom: $msv-account-profile-heading-padding-bottom;
            border-bottom: 0.5px solid $msv-gray-50;
            color: var(--msv-account-landing-tile-heading-color);
            margin-bottom: $msv-account-profile-heading-margin-bottom;
        }

        .ms-account-profile__section {
            margin-bottom: $msv-account-profile-section-margin-bottom;

            &-heading {
                @include font-heading-h5-l();
                margin-bottom: $msv-account-profile-section-heading-margin-bottom;
            }

            &-description {
                @include font-body-regular-m();
                margin-bottom: $msv-account-profile-description-margin-bottom;
            }

            &-preferences {
                margin-top: $msv-account-profile-section-preferences-margin-top;

                .ms-account-profile__section-heading {
                    @include font-heading-h3-l();
                    padding-bottom: $msv-account-profile-section-preferences-heading-padding-bottom;
                    border-bottom: 0.5px solid $msv-gray-50;
                    margin-bottom: $msv-account-profile-section-preferences-heading-margin-bottom;
                }

                .ms-account-profile__preference {
                    &-heading {
                        @include font-heading-h5-l();
                        margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                    }

                    &-description {
                        @include font-body-regular-m();
                    }
                }

                .ms-account-profile__preference-web-tracking {
                    margin-top: $msv-account-profile-section-preferences-web-tracking-margin-top;
                }
            }

            &-links {
                .ms-account-profile__section-link {
                    @include button-link($color: $msv-gray-20);
                    @include link-regular-m();
                    border-radius: 0;
                    padding: $msv-account-profile-links-padding;
                }
            }
        }
    }

    .ms-account-profile__attributes-wrapper {
        width: calc(5 * #{$msv-column-width-s});

        .ms-account-profile__attributes {
            &-tile__heading {
                @include font-heading-h3-l();
                padding-bottom: $msv-account-profile-heading-padding-bottom;
                border-bottom: 0.5px solid $msv-gray-50;
                color: var(--msv-account-landing-tile-heading-color);
                margin-bottom: $msv-account-profile-heading-margin-bottom;
            }

            &__section {
                margin-bottom: $msv-account-profile-attributes-section-margin-bottom;

                &-heading {
                    @include font-heading-h5-l();
                    margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                }

                &-description {
                    @include font-body-regular-m();
                }
            }

            &-edit-button {
                @include button-link($color: $msv-gray-20);
                @include link-regular-m();
                border-radius: 0;
                padding: $msv-account-profile-links-padding;
            }

            &_input-edit-false, &_select-edit-false {
                background: transparent;
                border: none;
                outline: none;
                color: $msv-gray-20;

                @include font-body-regular-m();
            }

            &_input-edit-true, &_select-edit-true {
                @include form-input-box();
            }

            &_select-edit-false {

                padding: 0;
            }

            &-save-button {
                @include primary-button-light();
                margin-right: $msv-account-profile-attributes-section-save-button-margin-right;

                @media screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
            }

            &-cancel-button {
                @include secondary-button-light();

                @media screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
                margin-top: $msv-account-profile-cancel-button-margin-top;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;

        .ms-account-profile-wrapper, .ms-account-profile__attributes-wrapper {
            width: $msv-width-full;
        }
    }
}

.ms-account-profile,
.ms-account-profile .ms-account-profile__attributes-wrapper .ms-account-profile__attributes__section {
    &__toggle-wrapper {
        margin-top: $msv-account-profile-section-preferences-toggle-margin-top;

        @include font-body-regular-m();
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-enable-button {
        @include toggle-button-on();
    }

    &__toggle-disable-button {
        @include toggle-button-off();
    }
}

.ms-account-profile .ms-account-profile__attributes-wrapper .ms-account-profile__attributes__section.ms-account-profile__attributes-VAT_Number {
    margin-bottom: $msv-account-profile-vat-margin-bottom;
}
