$breakpoint-475: 475px;
$breakpoint-768: 768px;
$breakpoint-992: 992px;
$breakpoint-1440: 1440px;

$breakpoints: (
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);

@mixin media-breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}
